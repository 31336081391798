import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-popover2',
  templateUrl: './popover2.page.html',
  styleUrls: ['./popover2.page.scss'],
})
export class Popover2Page implements OnInit {

  constructor(popoverController: PopoverController,public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
  }
  Cerrar() {
    this.global.Popover.dismiss(null, 'Cancelado');
}

Guardar() {
  this.global.Popover.dismiss(null, 'Cargado');
}
}
